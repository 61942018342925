import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import Nav from "../navigation/nav.component";
import Logo from "../logo/logo.component";
// import ChristmasGarland from "./decorations/christmas-garland";

import IS_LOGGED_IN from "../../graphql/queries/login.query";

import { StyledHeader, StyledHeaderContent, StyledHeaderWrapper } from "./styled-header";

const Header = ({ theme, toggleTheme }) => {

  // const [light, setLight] = useState(true);
  const [fixed, setFixed] = useState(false);

  const client = useApolloClient();

  const {
    data: { isLoggedIn },
  } = useQuery(IS_LOGGED_IN);

  let height = 50;
  let scrolled = 0;
  let ticking = false;

  const fixedHeader = (scrolled) => {
    if (scrolled > height) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  window.addEventListener("scroll", function () {
    scrolled = window.pageYOffset;

    if (!ticking) {
      window.requestAnimationFrame(function () {
        fixedHeader(scrolled);
        ticking = false;
      });
      ticking = true;
    }
  });

  useEffect(() => {
    const handleUserLoggedIn = () => {
      const token = localStorage.getItem("token");

      if (token) {
        const { id, role, createdAt, username } = jwt_decode(token);

        client.writeData({ data: { isLoggedIn: true, userId: id, userRole: role, username: username, createdAt } });
      }
    };

    window.addEventListener("userLoggedIn", handleUserLoggedIn);

    return () => {
      window.removeEventListener("userLoggedIn", handleUserLoggedIn);
    };
  }, []);

  return (
    <>
      {/*
        To add a Christmas garland, add the component and state "light" - <ChristmasGarland light={light} />.
      */}
      {/*<ChristmasGarland light={light} />*/}
      <StyledHeader id="header" fixed={fixed}>
        <StyledHeaderWrapper className="header__wrapper">
          <StyledHeaderContent className="header__content">
            <Logo fixed={fixed} />
            <Nav
              theme={theme}
              toggleTheme={toggleTheme}
              isLoggedIn={isLoggedIn}
              // light={light}
              // setLight={setLight}
            />
          </StyledHeaderContent>
        </StyledHeaderWrapper>
      </StyledHeader>
    </>
  );
};

export default Header;
